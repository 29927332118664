<template>
  <nav 
    class="bg-primary shadow-md fixed top-0 left-0 right-0 z-[100]" 
    role="navigation" 
    aria-label="Main navigation"
  >
    <!-- Skip link for keyboard users -->
    <a 
      href="#main-content" 
      ref="skipLink"
      class="sr-only focus:not-sr-only focus:absolute focus:z-[101] focus:p-4 focus:bg-primary focus:text-white focus:ring-2 focus:ring-primary rounded-md focus:translate-y-20 focus:left-4 transition-transform duration-200"
      @click="handleSkipLink"
    >
      Skip to main content
    </a>
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="relative flex items-center justify-between h-20">
        <div class="flex-1 flex justify-center sm:justify-start">
          <div class="flex items-center">
            <router-link 
              to="/" 
              class="logo-text"
              aria-label="Just Breathe - Go to Home Page"
            >
              just breathe
            </router-link>
          </div>
        </div>
        <div class="hidden sm:flex sm:ml-6 items-center space-x-8">
          <router-link
            v-for="link in filteredNavLinks"
            :key="link.to"
            :to="link.to"
            class="text-white hover:text-accent px-3 py-2 text-sm font-medium transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-primary rounded-md"
            :class="{ 'text-accent': currentRoute?.path === link.to }"
            :aria-current="currentRoute?.path === link.to ? 'page' : undefined"
          >
            {{ link.text }}
          </router-link>
          <template v-if="!isLoading && !(currentRoute?.path && currentRoute.path.includes('/passcode'))">
            <template v-if="isAuthenticated">
              <BaseButton
                variant="contrast"
                size="lg"
                class="px-4 py-2 text-base font-medium"
                @click="handleLogout"
                aria-label="Log out of your account"
              >
                Logout
              </BaseButton>
            </template>
            <template v-else>
              <div class="flex justify-center">
                <BaseButton
                  variant="contrast"
                  size="lg"
                  class="px-4 py-2 text-base font-medium"
                  :tag="'router-link'"
                  to="/login"
                  aria-label="Log in to your account"
                  @click.stop="closeMenu"
                >
                  Login
                </BaseButton>
              </div>
            </template>
          </template>
        </div>
        <div v-if="isLoading" class="flex items-center ml-4" role="status" aria-label="Loading user information">
          <div class="animate-spin rounded-full h-5 w-5 border-b-2 border-primary"></div>
        </div>
        <div class="flex items-center sm:hidden">
          <BaseButton
            variant="contrast"
            size="lg"
            class="w-12 h-12 flex items-center justify-center rounded-full"
            aria-controls="mobile-menu"
            :aria-expanded="menuOpen"
            @click.stop="toggleMenu"
          >
            <span class="sr-only">{{ menuOpen ? 'Close main menu' : 'Open main menu' }}</span>
            <svg
              :class="{'hidden': menuOpen, 'block': !menuOpen }"
              class="h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M4 6h16M4 12h16m-7 6h7"
              />
            </svg>
            <svg
              :class="{'block': menuOpen, 'hidden': !menuOpen }"
              class="h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </BaseButton>
        </div>
      </div>
      <transition
        enter-active-class="transition ease-out duration-200"
        enter-from-class="opacity-0 -translate-y-1"
        enter-to-class="opacity-100 translate-y-0"
        leave-active-class="transition ease-in duration-150"
        leave-from-class="opacity-100 translate-y-0"
        leave-to-class="opacity-0 -translate-y-1"
      >
        <div
          v-show="menuOpen"
          id="mobile-menu"
          ref="mobileMenuRef"
          class="sm:hidden fixed top-20 inset-x-0 bottom-0 bg-primary z-[99]"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="mobile-menu-button"
        >
          <div class="px-6 pt-4 pb-6 space-y-4 h-full overflow-y-auto">
            <router-link
              v-for="link in filteredNavLinks"
              :key="link.to"
              :to="link.to"
              class="block w-full text-center py-3 text-lg font-medium text-white hover:text-accent transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-primary rounded-md"
              :class="{ 'text-accent': currentRoute?.path === link.to }"
              :aria-current="currentRoute?.path === link.to ? 'page' : undefined"
              role="menuitem"
              @click.stop="closeMenu"
            >
              {{ link.text }}
            </router-link>
            <template v-if="!isLoading && !(currentRoute?.path && currentRoute.path.includes('/passcode'))">
              <template v-if="isAuthenticated">
                <BaseButton
                  variant="contrast"
                  size="lg"
                  class="w-full px-4 py-2 text-base font-medium"
                  @click="handleLogout"
                  aria-label="Log out of your account"
                >
                  Logout
                </BaseButton>
              </template>
              <template v-else>
                <div class="flex justify-center">
                  <BaseButton
                    variant="contrast"
                    size="lg"
                    class="w-full px-4 py-2 text-base font-medium"
                    :tag="'router-link'"
                    to="/login"
                    aria-label="Log in to your account"
                    @click.stop="closeMenu"
                  >
                    Login
                  </BaseButton>
                </div>
              </template>
            </template>
          </div>
        </div>
      </transition>
    </div>
  </nav>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, onUnmounted, watch, nextTick } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { storeToRefs } from 'pinia'
import { eventBus, MENU_EVENT } from '@/utils/eventBus'
import { useUserStore } from '@/stores/user'
import { useAuthStore } from '@/stores/auth'
import { logger } from '@/utils/logger'
import BaseButton from '@/components/BaseButton.vue'

const logOptions = { module: 'NavigationBar' }
const skipLink = ref<HTMLElement | null>(null)
const isTransitioning = ref(false)
const transitionTimeout = ref<number | null>(null)
const TRANSITION_DURATION = 200 // Match the CSS transition duration

const router = useRouter()
const currentRoute = useRoute()
const userStore = useUserStore()
const authStore = useAuthStore()

// Use storeToRefs to maintain reactivity for store state
const { isLoading } = storeToRefs(userStore)
const { isAuthenticated } = storeToRefs(authStore)

// Watch auth state changes
watch(isAuthenticated, (newValue, oldValue) => {
  if (isAuthenticated) {  // Check if isAuthenticated exists before using it
    logger.debug('Auth state changed', { 
      ...logOptions, 
      metadata: { newValue, oldValue }
    });
    if (oldValue && !newValue) {
      logger.info('User logged out, redirecting to login', logOptions);
      router.push('/login')
    }
  }
})

// Menu state
const menuOpen = ref(false)
const mobileMenuRef = ref<HTMLElement | null>(null)

// Menu handlers with transition state management
const toggleMenu = () => {
  if (isTransitioning.value) {
    logger.debug('Menu transition in progress, ignoring toggle', logOptions);
    return;
  }

  isTransitioning.value = true;
  menuOpen.value = !menuOpen.value;
  eventBus.emit(MENU_EVENT);

  // Clear any existing transition timeout
  if (transitionTimeout.value) {
    clearTimeout(transitionTimeout.value);
  }

  // Set new transition timeout
  transitionTimeout.value = window.setTimeout(() => {
    isTransitioning.value = false;
    transitionTimeout.value = null;
    logger.debug('Menu transition complete', {
      ...logOptions,
      metadata: { menuOpen: menuOpen.value }
    });
  }, TRANSITION_DURATION);
}

const closeMenu = () => {
  if (menuOpen.value && !isTransitioning.value) {
    toggleMenu();
  }
}

const handleOutsideClick = (event: MouseEvent) => {
  const target = event.target as Node
  if (menuOpen.value && mobileMenuRef.value && !mobileMenuRef.value.contains(target)) {
    closeMenu()
  }
}

// Navigation links with proper typing
interface NavLink {
  to: string
  text: string
  name: string
  hideWhenLoggedIn?: boolean
  requiresAuth?: boolean
  alwaysShow?: boolean
}

const navLinks: NavLink[] = [
  { to: '/', text: 'Home', name: 'Home', alwaysShow: true },
  { to: '/about', text: 'About', name: 'About' },
  { to: '/browse', text: 'Browse', name: 'Browse' },
  { to: '/releases', text: 'Releases', name: 'Releases' },
  { to: '/now', text: 'Breathe', name: 'Now' },
  { to: '/services', text: 'Services', name: 'Services', requiresAuth: true },
  { to: '/clients', text: 'Clients', name: 'Clients', requiresAuth: true },        
  { to: '/email-templates', text: 'Emails', name: 'EmailTemplates', requiresAuth: true },
  { to: '/forms', text: 'Forms', name: 'Forms', requiresAuth: true },
  { to: '/settings', text: 'Settings', name: 'Settings', requiresAuth: true }
]

const filteredNavLinks = computed(() => {
  // Hide all navigation items on PasscodePage
  if (currentRoute?.path && currentRoute.path.includes('/passcode')) {
    return [];
  }

  // Normal navigation filtering logic
  if (isAuthenticated && isAuthenticated.value) {
    return navLinks.filter(link => link.alwaysShow || link.requiresAuth);
  }
  return navLinks.filter(link => !link.requiresAuth);
})

// Methods
const handleLogout = async () => {
  if (isTransitioning.value) {
    logger.debug('Route transition in progress, ignoring logout', logOptions);
    return;
  }

  logger.info('Handling logout', logOptions);
  await authStore.logout()
  await router.push({ name: 'Login' })
  logger.debug('Logout complete, redirected to login', logOptions);
}

// Handle skip link click with transition state
const handleSkipLink = async (event: Event) => {
  if (isTransitioning.value) {
    logger.debug('Route transition in progress, ignoring skip link', logOptions);
    return;
  }

  event.preventDefault()
  const mainContent = document.getElementById('main-content')
  if (mainContent) {
    // Set focus to main content
    mainContent.focus()
    // Ensure it's scrolled into view
    mainContent.scrollIntoView({ behavior: 'smooth' })
    logger.debug('Skip link clicked, focused main content', logOptions)
  } else {
    logger.warn('Main content element not found', logOptions)
  }
}

// Add a flag to track initial load
const isInitialLoad = ref(true)
const isKeyboardUser = ref(false)

logger.debug('Initial keyboard user state:', { ...logOptions, metadata: { isKeyboardUser: isKeyboardUser.value }})

// Modify the route watcher with transition state
watch(() => currentRoute?.path, async (newPath, oldPath) => {
  if (newPath !== oldPath) {
    // Set transitioning state
    isTransitioning.value = true;

    logger.debug('Route transition started', {
      ...logOptions,
      metadata: { from: oldPath, to: newPath }
    });

    // Close mobile menu if open during route change
    if (menuOpen.value) {
      menuOpen.value = false;
    }

    await nextTick();

    // Only focus skip link if using keyboard navigation and not initial load
    if (!isInitialLoad.value && isKeyboardUser.value) {
      skipLink.value?.focus();
      logger.debug('Focus set to skip link', logOptions);
    }

    isInitialLoad.value = false;

    // Clear any existing transition timeout
    if (transitionTimeout.value) {
      clearTimeout(transitionTimeout.value);
    }

    // Set new transition timeout
    transitionTimeout.value = window.setTimeout(() => {
      isTransitioning.value = false;
      transitionTimeout.value = null;
      logger.debug('Route transition complete', {
        ...logOptions,
        metadata: { currentPath: newPath }
      });
    }, TRANSITION_DURATION);
  }
})

// Enhanced lifecycle hooks
onMounted(() => {
  document.addEventListener('click', handleOutsideClick)
  
  // Add keyboard event listener for ESC key to close menu
  document.addEventListener('keydown', (event: KeyboardEvent) => {
    if (event.key === 'Escape' && menuOpen.value) {
      closeMenu()
    }
  })
  
  // Declare handleKeyDown function
  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Tab') {
      isKeyboardUser.value = true
      logger.debug('Tab key pressed, keyboard user:', { ...logOptions, metadata: { isKeyboardUser: isKeyboardUser.value }})
    }
  }

  document.addEventListener('keydown', handleKeyDown)
  logger.debug('NavigationBar mounted with keyboard listeners', logOptions)

  // Store the handler for cleanup
  onUnmounted(() => {
    document.removeEventListener('click', handleOutsideClick)
    document.removeEventListener('keydown', handleKeyDown)
    
    // Clear any pending timeouts
    if (transitionTimeout.value) {
      clearTimeout(transitionTimeout.value);
      transitionTimeout.value = null;
    }
  })
})
</script>

<style scoped>
@font-face {
  font-family: 'acroScript';
  src: url('@/assets/fonts/acroScript.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

.logo-text {
  font-family: 'acroScript', cursive;
  font-size: 2rem;
  color: white;
  text-align: center;
  font-weight: 400;
  line-height: 1;
  padding: 0.5rem 0;
  display: block;
  letter-spacing: 0.5px;
  position: relative;
}

.logo-text::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: white;
  transition: width 0.3s ease;
}

.logo-text:hover::after {
  width: 100%;
}

.logo-text:focus {
  outline: none;
  box-shadow: 0 0 0 2px white;
  border-radius: 4px;
}

/* Custom button styles */
.btn-primary {
  @apply bg-primary text-white px-6 py-2 rounded-full hover:bg-opacity-90 transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-primary;
}

.btn-secondary {
  @apply border-2 border-primary text-white px-6 py-2 rounded-full hover:bg-primary hover:text-white transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-primary;
}

/* Mobile menu overlay */
@media (max-width: 640px) {
  .mobile-menu-open {
    overflow: hidden;
  }
}

/* Enhanced skip link styles */
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only:focus {
  width: auto;
  height: auto;
  clip: auto;
  clip-path: none;
  white-space: normal;
  padding: 1rem;
  background-color: var(--color-secondary);
  color: white;
  font-weight: 500;
  border-radius: 0.375rem;
  outline: none;
  box-shadow: 0 0 0 2px var(--color-primary);
}
</style>
